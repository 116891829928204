import React from 'react'
import DatePicker from 'react-datepicker';
import Select from 'react-select';

const MyTasksFilter = (props) => {

    let checkpointsOptions = [
        { 'value': '', 'label': 'Всички' }
    ];
    Object.values(props.checkpoints).map(checkpoint => {
        checkpointsOptions.push({ 'value': checkpoint.id, 'label': checkpoint.name });
    });

    return (
        <div className="container-fluid">
            <div className="animated fadeIn">
                <div className="row">

                    <div className="col-md-2 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="parentId">{props.translations.tasks.checkpoint}</label>
                        
                            <Select
                                value={props.search_checkpoint}
                                name="colors"
                                options={checkpointsOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(valueLabel) => props.onFieldChange('search_checkpoint', valueLabel.value)}
                            // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                            />

                        </div>
                    </div>

                    {/* <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">

                            <button className="btn btn-info" onClick={props.filter} style={{marginTop: 28}}>{props.translations.common.filter}</button>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default MyTasksFilter;