import React from 'react';
import { Switch, Route, Router, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './store'

//SUPER ADMIN
//PLACES
import SetCurrPlacePage from './components/places/components/SetCurrPlacePage';

// Users
import ListUsersPage from './components/users/ListUsersPage';
import ListRegisteredUsersPage from './components/users/registered/ListRegisteredUsersPage';
import AddUserPage from './components/users/AddUserPage';
import EditUserPage from './components/users/EditUserPage';
// Orders
// import ListAllOrdersPage from './components/orders/ListAllOrdersPage';

import LoginPage from './components/login/Login';
import PrivateRoute from './utils/PrivateRoute';

// PLACES
import ListPlacesPage from './components/places/components/ListPlacesPage';
import AddPlacePage from './components/places/components/AddPlacePage';
import EditPlacePage from './components/places/components/EditPlacePage';

import ListTasksPage from './components/tasks/components/ListTasksPage';
import AddTaskPage from './components/tasks/components/AddTaskPage';
import EditTaskPage from './components/tasks/components/EditTaskPage';

import ListCheckpointTasksForUserPage from './components/tasks/components/scan/ListCheckpointTasksForUserPage';
import ListUserTasksPage from './components/tasks/components/userTasks/ListUserTasksPage';

// Checkpoints
import ListCheckpointsPage from './components/checkpoints/components/ListCheckpointsPage';
import AddCheckpointPage from './components/checkpoints/components/AddCheckpointPage';
import EditCheckpointPage from './components/checkpoints/components/EditCheckpointPage';
import ListCheckpointTasks from './components/checkpoints/components/tasks/ListCheckpointTasks';
import AddCheckpointTaskPage from './components/checkpoints/components/tasks/AddCheckpointTask';
import EditCheckpointTaskPage from './components/checkpoints/components/tasks/EditCheckpointTaskPage';

const MyRouter = (props) => (
    <Router history={history}>
        <Switch>
            <Route exact path='/' component={LoginPage} />
            <Route exact path='/login' component={LoginPage} />

            <PrivateRoute exact path='/my-tasks' component={ListUserTasksPage} />
            <PrivateRoute exact path='/tasks/t/:token' component={ListCheckpointTasksForUserPage} />

            <PrivateRoute exact path='/admin/tasks' component={ListTasksPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/tasks/add' component={AddTaskPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/tasks/edit/:id' component={EditTaskPage} requiredRole="ADMIN"/>

            <PrivateRoute exact path='/admin/checkpoints' component={ListCheckpointsPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/checkpoints/add' component={AddCheckpointPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/checkpoints/edit/:id' component={EditCheckpointPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/checkpoints/:id/tasks' component={ListCheckpointTasks} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/checkpoints/:id/tasks/add' component={AddCheckpointTaskPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/checkpoints/:id/tasks/:taskId/edit' component={EditCheckpointTaskPage} requiredRole="ADMIN"/>


            {/* SUPER_ADMIN */}
            {/* PLACES */}
            <PrivateRoute exact path='/admin/places/current' component={SetCurrPlacePage} requiredRole="SUPER_ADMIN"/>
            <PrivateRoute exact path='/admin/places' component={ListPlacesPage} requiredRole="SUPER_ADMIN"/>
            <PrivateRoute exact path='/admin/places/add' component={AddPlacePage} requiredRole="SUPER_ADMIN"/>
            <PrivateRoute exact path='/admin/places/edit/:id' component={EditPlacePage} requiredRole="SUPER_ADMIN"/>

            {/* Users */}
            <PrivateRoute exact path='/admin/users' component={ListUsersPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/users/registered' component={ListRegisteredUsersPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/users/add' component={AddUserPage} requiredRole="ADMIN"/>
            <PrivateRoute exact path='/admin/users/edit/:id' component={EditUserPage} requiredRole="ADMIN"/>
    
        </Switch>
    </Router>
)

const mapStateToProps = (state, dispatch) => {
    return {
        userRole: state.auth.user.role || null,
    }
};

export default connect(mapStateToProps, {})(MyRouter);
