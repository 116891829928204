export const bgTranslations = {
    sidemenu: {
        my_tasks: "Моите задачи",
        tasks: "Задачи",
        checkpoints: "Локации",
        
        admin: "Админ",
        dashboard: "Начало",
        administration: "Админ",
        users: "Потребители",
        system: "Системни",
        super_admin: "Супер админ",
        current_place: "Текущ обект",
        clients: "Клиенти",
        place: "Обект",
        places: "Обекти",
    },
    common: {
        next: 'Следващ',
        from: 'От',
        to: 'До',
        no_internet: 'Липсва връзка с интернет',
        back: "Назад",
        title: "Заглавие",
        id: "Номер",
        dashboard: "Начало",
        name: "Име",
        status: "Статус",
        action: "Действие",
        client: "Клиент",
        date: "Дата",
        active: "Активна",
        inactive: "Неактивна",
        description: "Описание",
        image: "Снимка",
        yes: "Да",
        no: "Не",
        add: "Добави",
        cancel: "Откажи",
        save: "Запази",
        delete: "Изтрий",
        close: "Затвори",
        edit: "Редактирай",
        download: "Свали",
        role: "Роля",
        filter: "Филтър",
        place: "Обект",
        rotation_start_date: "Начална дата на ротация",
        rotation_start_date_info: "",
        rotation_days_count: "Дни на ротация",
        rotation_days_count_info: "",
        deleted: "Изтрит",
        user: "Потребител",
        logout: "Изход",
        profile: "Профил",
    },
    tasks: {
        title: "Задачи",
        description: "Описание",
        rotation_start_date: "Начална дата на изпълнение",
        rotation_days_count: "През колко дни да се изпълнява",
        times_per_day_of_task_execution: "Брой изпълнения на ден",
        users: "Потребители изпълняващи задачата",
        complete_tasks: "Изпълни",
        completed: "Изпълнена",
        not_completed: "Неизпълнена",
        checkpoint: "Локация",
    },
    checkpoints: {
        title: "Локации",
        generate_qr: "Генерирай нов QR",
        tasks_for_checkpoint: "Задачи",
        add_task: "Добави задача",
        add: "Добави локация",
        edit: "Промяна на локация",
    },
    versions: {
        features_about_version: "Нови функционалности за версия:",
        version: "Версия"
    },
    places: {
        title: "Обекти",
        add: "Добави обект",
        edit: "Промяна на обект",
        ordertypes: "Типове поръчка",
        edit: "Промяна",
        delete: {
            title: "Изтрий обект",
            confirm: "Сигурен ли си, че искаш да изтриеш обекта "
        },
        init_settings_for_all_places: "Инициализирай настройките за всички обекти",
        functionality_modules: "Функционални модули",
        sms_logs: "SMS-и по месеци",
        additional_info: "Допълнителна информация (договорки)",
        company_email: "Имейл",
        lat: "Lat",
        lng: "Lng",
        short_name: "Кратко име",
        company_name: "Фирма",
        company_eik: "ЕИК",
        company_city: "Град",
        company_address: "Адрес",
        company_person: "МОЛ",
        company_email: "Имейл",
        price: "Цена",
        company_vat_registered: "ДДС",
        is_paying: "Плащащ",
    },
    messages: {
        errors: {
            invalid_name: "Невалидно име",
            invalid_email: "Невалиден мейл",
            invalid_role: "Невалидна роля",
            you_must_select_place: "Трябва да изберете обект",
            invalid_place: "Невалиден обект",
            invalid_credentials: "Невалидни данни за вход",
            "Wrong checkpoint for task": "Невалидна локация за задачата",
            "Invalid checkpoint": "Невалидна локация за задача"
        }
    },
    users: {
        add: "Добави",
        edit: "Промени",
        email: "Имейл",
        password: "Парола",
        date_of_registration: "Дата на създаване",
        fullname: "Име",
        phone: "Телефон",
    },
    roles: {
        SUPER_ADMIN: "Супер Админ",
        ADMIN: "Админ",
        USER: "Обикновен потребител"
    },
    login: {
        title: "Влез в профила си",
        email: "Имейл",
        password: "Парола",
        login: "Влез"
    },
}